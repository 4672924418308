import './App.css';
import { useState, useEffect } from "react";
import axios from "axios";
import { Route,Routes,BrowserRouter} from "react-router-dom";
import Dashboard from './Dashboard/Pages/Dashboard';
import Patients_List from './Dashboard/Pages/Patients_List';
import LoginPage from './Dashboard/Pages/LoginPage';
import Protected from './Dashboard/Pages/Protected';
import Appointment_list from './Dashboard/Pages/Appointment_List';
import Appointment_create from './Dashboard/Pages/Appointment_create';

import "react-toastify/dist/ReactToastify.css";
import Abhalist from './Dashboard/Pages/Abhalist';

import Invoice from './Dashboard/Pages/Invoice';
import Transaction_Details from './Dashboard/Pages/Transaction_Details';
import Wallet_details from './Dashboard/Pages/Wallet_details';
import Prescription from './Dashboard/Pages/Prescription';
import InvoiceDetail from './Dashboard/Pages/InvoiceDetail';
import Invoice_Genrate from './Dashboard/Pages/Invoice_Genrate';
import Paginate from './Dashboard/Pages/Paginate';
import TopupList from './Dashboard/Pages/TopupList';
import CreateInvoice from './Dashboard/Pages/CreateInvoice';
import Feedback from './Dashboard/Pages/Feedback';
import Patientreport from './Dashboard/Pages/Patientreport';
import { Appointment_view } from './Dashboard/Pages/Appointment_view';
import Abhacard from './Dashboard/Pages/Abhacard';
import { AbhaLogin } from './Dashboard/Pages/AbhaLogin';
import { AbhaValidate } from './Dashboard/Pages/AbhaValidate';
import { AbhaPreview } from './Dashboard/Pages/AbhaPreview';
import { Abhaotp } from './Dashboard/Pages/Abhaotp';
import Abhaadharpng from './Dashboard/Pages/Abhaadharpng';
import { Abhamobileotp } from './Dashboard/Pages/Abhamobileotp';
import Demo from './Dashboard/Pages/agoravideo/Demo';
import Authenticate from './Dashboard/Pages/Authenticate';

function App() { 
  return (
    <>
        <BrowserRouter>
        <Routes suppressNoMatchWarning={true}>
        <Route path="/dashboard" element={<Protected><Dashboard /></Protected>} />
        
          <Route path="/patient" element={<Protected><Patients_List /></Protected>} />
          <Route path="/appoint_list" element={<Protected><Appointment_list /></Protected>} />
          <Route path="/topup_list" element={<TopupList />} />
          <Route path="/appointment_create" element={<Protected><Appointment_create /></Protected>} />
          <Route path="/transaction" element={<Protected><Transaction_Details /></Protected>} />
          <Route path="/wallet" element={<Protected><Wallet_details /></Protected>} />
          {/* <Route path="/appoint" element={<Protected><Appointment_list /></Protected>} /> */}
          <Route path="/" element={<LoginPage />} />
          <Route path="/prescription" element={<Prescription />} />
          <Route path="/invoice-detail" element={<Protected><InvoiceDetail /></Protected>} />
          <Route path="/invoice" element={<Protected><Invoice /></Protected>} />
          <Route path="/invoice-generate" element={<Protected><Invoice_Genrate /></Protected>} />
          <Route path="/paginate" element={<Protected><Paginate /></Protected>} />
          <Route path="/createinvoice" element={<Protected><CreateInvoice /></Protected>} />
          <Route path="/feedback" element={<Protected><Feedback /></Protected>} />
          <Route path="/patientreport" element={<Protected><Patientreport /></Protected>} />
          <Route path="/appointment-view" element={<Appointment_view />} />
          <Route path="/abha" element={<Abhacard />} />
          <Route path="/abha-login" element={<AbhaLogin />} />
          <Route path="/abha-validate" element={<AbhaValidate />} />
          <Route path="/abha-preview" element={<AbhaPreview />} />
          <Route path="/abha-list" element={<Abhalist />} />
          <Route path="/abhaotp" element={<Abhaotp />} />
          <Route path="/abha-adhar-png" element={<Abhaadharpng />} />
          <Route path="/abha-mobileotp" element={<Abhamobileotp />} />
          <Route path="/video" element={<Demo />} />
          <Route path="/auth" element={<Authenticate/>}/>
        
        </Routes>
    </BrowserRouter>
        
    </>
  );
}

export default App;
