import React, { useState, useEffect } from "react";
import Dashboard_layout from "../Layout/Dashboard_layout";
import moment from "moment";
import axios from "axios";
import { useLocation } from "react-router-dom";

import { Dialog } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { Button, Col, Container, Form, FormLabel, Row } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import axiosNew from "./../../http/axios/axios_new";
import axiosTrans from "../../http/axios/axios_trans";
import useAuth from "../../hooks/useAuth";
import translators from "../../Helper/TranslationHelper";
import { CheckCircle,CheckCircleOutline } from "@material-ui/icons";
const Prescription = () => {
  const { user, last_login } = useAuth();

  const [pres, setpres] = useState();
  const [drname, setdrname] = useState();
  const [showed, setShow] = useState(false);

  const [reportshow, setreportShow] = useState(false);

  const [diagnos, setdiagnos] = useState([]);

  const [degree, setdegree] = useState();

  const [reg, setreg] = useState();

  const [phone, setphone] = useState();

  const [pname, setpname] = useState();

  const [age, setage] = useState();

  const [consultdate, setconsultdate] = useState();

  const [pat_id, setpat_id] = useState();

  const [ccomplain, setccomplain] = useState();

  const [temp, setTemp] = useState();
  const [pulse, setPulse] = useState();
  const [rate, setRate] = useState();
  const [bp, setBp] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeigth] = useState();
  const [spo2, setSpo2] = useState();
  const [fbs, setFbs] = useState();
  const [rbs, setRbs] = useState();
  const [ppbs, setPpbs] = useState();
  const [blood_group, setBlood_group] = useState();
  const [Chiefcomplains, setChief_complains] = useState(false);
  const [past_history, setPast_history] = useState([]);
  const [personal_history, setPersonal_history] = useState();
  const [menstrual, setMenstrual] = useState();
  const [allergies, setAllergies] = useState();
  const [gender_pat, setgender_pat] = useState(false);

  const [pres_item, setpres_item] = useState(false);

  const [padvice, setpadvice] = useState([]);
  const [prov, setprov] = useState([]);
  const [inves, setinves] = useState([]);
  const [addnote, setaddnote] = useState([]);
  const [fileprocess, setfileprocess] = useState(false);
  const [languageData,setLanguageData] = useState([]);
  const [translateData,setTranslateData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);
  const { state } = useLocation("/appoint_list");
  const [chosenLanguage,setChosenLanguage] = useState('en')

  const { presid } = state;
  function triggerLanguageChangeOnSelection(lang){
    setChosenLanguage(lang)
    localStorage.setItem('prescriptionLocale',lang)
    if(lang !== "en"){
      syncLanguage();
      handleModalClose();
    }else{
      window.location.reload(false);
    }
  }
  const syncLanguage = async () =>{
    
    if(chosenLanguage!=="en"){
      console.log('......triggered.......')
      const language = await axiosNew.get("api/translations/hindi");
      console.log(language);
      setLanguageData(language.data)
      setTranslateData(language.data.translation_keys)
      triggerTranslation(language.data.translation_keys);
      checkLanguageForTranslation()
    }
  }
  
  const triggerValueTranslation = async () => {
    var elements = document.getElementsByClassName('translateValue');
    for (var i of elements){
      if(i.innerHTML != "" && i.innerHTML != null && i.innerHTML != undefined ){
        const translate = await axiosTrans.post("/translate?api-version=3.0&from=en&to="+chosenLanguage,[{text:i.innerHTML}])
        console.log(translate.data[0].translations[0].text)
        i.innerHTML = translate.data[0].translations[0].text;
      }
    }
  }
  
  const getApiTranslatedValues = async (data) => {
    return translators.azureTranslate(data)
  }
  function triggerTranslation(languageJson){
    var elements = document.getElementsByClassName('translate');
    console.log(elements)    
    console.log(languageJson)
    for (var i of elements){
      if(languageJson[i.getAttribute('data-translate_key')]!== undefined && languageJson[i.getAttribute('data-translate_key')] !== null && languageJson[i.getAttribute('data-translate_key')]!== null){
        if(languageJson[i.getAttribute('data-translate_key')]!=""){
          i.innerHTML = languageJson[i.getAttribute('data-translate_key')]
        }
      };
    }
  }
  function getFullForm(alphabet,index){
   // console.log(alphabet,index)
    var sentenceArray = alphabet.split("+") 
    var alphabetToConvert = '';
    var extractLetter = '';
    if(sentenceArray[index] !== '' && sentenceArray[index] != null && sentenceArray[index] !== undefined){ 
      extractLetter =  sentenceArray[index].split(" ");
      alphabetToConvert  = extractLetter[1];
    }
    var alphabetToReturn = '';
    var tabletTiming='';
    switch(alphabetToConvert){
      case "M" : alphabetToReturn = "Morning,";
                  break;
      case "A" : alphabetToReturn = " Afternoon,";
                  break;
      case "E" : alphabetToReturn = " Evening,";
                  break;
      case "N" : alphabetToReturn = " Night";
                  break;
      case "null" : alphabetToReturn = "";
                  break;
      default : alphabetToReturn = "";
                  break;
    }
   /// console.log(sentenceArray,extractLetter,alphabetToConvert,extractLetter[0] +" "+ alphabetToReturn)
    var  tabletTiming = '';
    if(extractLetter !== '' && extractLetter !== null){
      if(extractLetter[0] != 0){
        tabletTiming = extractLetter[0] +" "+ alphabetToReturn;
      }      
    }
    return tabletTiming;
  }
  const getpres = async () => {
    

    const api = await axiosNew.post(
      // "https://api.medyseva.com/api/doctor/prescription/view",
      "api/doctor/prescription/view",

      {
        prescription_id: presid,
      }
    );

   
    console.log(api, "yesid_prescription");
    setpres(api.data.data.prescription);

    setdiagnos(api.data.data.diagnosis_reports);

    setdrname(api.data.data.prescription.user.name);
    setdegree(
      api.data.data.prescription.user.degree.replace(/(<([^>]+)>)/gi, "")
    );
    setreg(api.data.data.prescription.user.reg_no);

    setphone(api.data.data.prescription.user.phone);
    setpname(api.data.data.prescription.patient.name);

    setage(api.data.data.prescription.patient.age);
    setgender_pat(api.data.data.prescription.patient.sex);

    setconsultdate(api.data.data.prescription.created_at);

    setpat_id(api.data.data.prescription.patient_id);

    setTemp(api.data.data.prescription.t);
    setPulse(api.data.data.prescription.p);

    setRate(api.data.data.prescription.r);
    setBp(api.data.data.prescription.bp);
    setHeight(api.data.data.prescription.ht);
    setWeigth(api.data.data.prescription.wt);
    setSpo2(api.data.data.prescription.spo2);
    setFbs(api.data.data.prescription.fbs);

    setRbs(api.data.data.prescription.rbs);
    setPpbs(api.data.data.prescription.ppbs);
    setBlood_group(api.data.data.prescription.blood_group);
    setChief_complains(
      api.data.data.prescription.chief_complains != ""
        ? JSON.parse(api.data.data.prescription.chief_complains)
        : ""
    );

    setPast_history(
      api.data.data.prescription.past_history != ""
        ? JSON.parse(api.data.data.prescription.past_history)
        : ""
    );
    setinves(api.data.data.pre_investigation);
    setPersonal_history(
      api.data.data.prescription.personal_history != ""
        ? JSON.parse(api.data.data.prescription.personal_history)
        : ""
    );
    setMenstrual(
      api.data.data.prescription.med_histry != ""
        ? JSON.parse(api.data.data.prescription.med_histry)
        : ""
    );

    setAllergies(
      api.data.data.prescription.allergies != ""
        ? JSON.parse(api.data.data.prescription.allergies)
        : ""
    );

    setpres_item(api.data.data.prescription.items);
    setpadvice(api.data.data.pre_advice);
    setprov(api.data.data.pre_diagonosis);

    setaddnote(api.data.data.pre_ad_advices);
    // console.log(typeof(api.data.data.prescription.chief_complains),"done")
    checkLanguageForTranslation();
  };

  function checkLanguageForTranslation(){
    if(localStorage.getItem('presriptionLocal')!=="en"){
      triggerValueTranslation()
    }
  }
  const handleTopup = async (data) => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  useEffect(() => {
    getpres();
  }, []);

  useEffect(() => {
    if(localStorage.getItem('prescriptionLocale')!== "" && localStorage.getItem('prescriptionLocale')!= undefined && localStorage.getItem('prescriptionLocale')!== null){      
      if(localStorage.getItem('prescriptionLocale')!=='en'){
        syncLanguage();
      }
    }
  }, []);
  const handletopup1 = async (data) => {
    setreportShow(true);
  };
  const reporthandleClose = () => {
    setreportShow(false);
  };

  console.log(Chiefcomplains, "rxx");

  let des = "data";

  const [photo, setPhoto] = useState([]);
  const [filemuti, setfilemuti] = useState("");
  // const { file, photoUrl, loading } = photo;

  const photoUploadHandler = async (event, setState, { id: diagnosid }) => {
    const { files } = event.target;
    console.log(files, "mnr");

    console.log(diagnosid, "daignosisid");
    setfileprocess(true)
    let photos = await Promise.all(
      [...files].map(async (item, index) => {

        // console.log(files[index].size / 1048576, 'okokokokokok');
        const fileSize_mb = files[index].size / 1048576;

        if (fileSize_mb > 2) {
          toast.error(files[index].name + '' + 'File size exceeds 2 MiB');

          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          // console.log(files[index].size / 1048576, 'inside else');

          if (files[index]) {
            if (
              files[index].type.includes("image") ||
              files[index].type.includes("pdf")
            ) {
              const filename = files[index].name;
              const fileExtension = filename.substr(
                filename.lastIndexOf(".") + 1
              );

              if (
                fileExtension.toLowerCase() === "png" ||
                fileExtension.toLowerCase() === "pdf" ||
                fileExtension.toLowerCase() === "jpg" ||
                fileExtension.toLowerCase() === "gif" ||
                fileExtension.toLowerCase() === "jpeg" ||
                fileExtension.toLowerCase() === "webp"
              ) {
                const vv = event.target.files[index];

                const formData_temp = new FormData();

                formData_temp.append("temporary_file", vv);

                // console.log(diagnosid, 'daignosisid');

                try {
                  let tempapi = await axiosNew.post(
                    "/api/vle/prescription/upload/temp_report",
                    formData_temp,
                    {
                      headers: {
                        "Content-Type":
                          "multipart/form-data; boundary=c9236fb18bed42c49590f58f8cc327e3",
                      },
                    }
                  );
                  console.log(tempapi.data.data, "mnr-indoo");

                  return tempapi.data.data;
                } catch (error) {
                  console.log(error, 'pdferror');
                }
              }
              // console.log(tempapi.data.data, "mnr-indoo");
            }
          }
        }
        return;
      })

    );
    setfileprocess(false);
    const formData = new FormData();
    setPhoto((prev) => {
      let temp = prev;
      let newstate = [
        ...prev,
        {
          diagnosis_id: diagnosid,
          files: photos,
        },
      ];
      return newstate;
    });
  };
  const HandleSubmit = async () => {


    try {




      if (photo == '') {

        toast.warn('Please choose file');

      } else {

        console.log("datasub");
        let totaldata = {
          prescription_id: presid,
          diagnosis_report: photo,
        };

        // const formData = new FormData();
        // for (let i = 0; i < this.state.image.length; i++) {
        //   data.append("images[]", this.state.image[i]);
        // }
        const formData = new FormData();

        formData.append("prescription_id", presid);
        formData.append("diagnosis_report", photo);

        console.log(totaldata, "qw");

        console.log(photo, "file-totaldata.diagnosis_report");

        //
        const pay_arr = JSON.stringify(totaldata);

        console.log(pay_arr, "pay_arrpay_arr");

        console.log(totaldata, "file-mpnew");
        let result = await axiosNew.post(
          // "https://api.medyseva.com/api/vle/prescription/upload/patient_diagnosis_report", formData, {
          "/api/vle/prescription/upload/patient_diagnosis_report",
          pay_arr,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (result.data.status == 1) {
          setShow(false);
          toast.success(" Sent Successfully");
          // getpres();
          // document.getElementsByClassName('inputTag').value='';

          setTimeout(function () {
            // getpres();
            // document.body.style.cursor = "wait";
            window.location.reload();
          }, 1000);

          console.log(result, "finaldata");
        } else {
          toast.error("Failed");
        }
      }


    }


    catch (error) {
      console.log(error, "ye error");
    }
  };

  console.log(diagnos, "diagnosdiagnosdiagnosdiagnos");

  //   function clearF(target) {
  //         target.value = "";
  // }

  return (
    <div>
      <>
        <Dashboard_layout>
          <ToastContainer />
          {/* <div classes={{paperFullScreen: "prePrint"}} fullScreen > */}
          <div className="content-top-padding"></div>
          <div className="row">
            <div className="col-md-10 m-auto mt-50">
              <div className="hidden-print">
              <a
                href=""
                onClick={(e) => window.print()}
                class="btn btn-light-secondary btn-sm print_but"
                data-toggle="tooltip"
                data-placement="top"
                title="View"
              >
                Print
              </a>
              <a
                style={{ marginLeft: 10 }}
                // onClick={(e) => window.print()}

                class="btn btn-light-secondary btn-sm print_but"
                data-toggle="tooltip"
                data-placement="top"
                title="View"
                onClick={(e) => handleTopup()}
              >
                Upload Report
              </a>
              <a
                style={{ marginLeft: 10 }}
                // onClick={(e) => window.print()}

                class="btn btn-light-secondary btn-sm print_but"
                data-toggle="tooltip"
                data-placement="top"
                title="View"
                onClick={(e) => handletopup1()}
              >
                View Report
              </a>
              <a
                style={{ marginLeft: 10 }}
                // onClick={(e) => window.print()}

                class="btn btn-light-secondary btn-sm print_but"
                data-toggle="tooltip"
                data-placement="top"
                title="Select Language"
                onClick={(e) => handleModalShow()}
              >
                Select Language
              </a>
              </div>
              <div
                className="card shadow mb-4"
              //  id="DialogPrint"
              >
                <div className="card-header header-body py-3">
                  <div className="prescription_headers1">
                    <div className="row">
                      <div className="col-md-7 text-left pre-header printhl">
                        <p> <div className="translate translatealign" data-translate_key="doctor_name">Doctor Name </div> - <div className="translatealign" >{drname}</div></p>
                        <p> <div className="translate translatealign" data-translate_key="degree">DEGREE </div> - <div className="translatealign" >{degree}</div></p>
                        <p> <div className="translate translatealign" data-translate_key="registration_number">REGISTRATION NUMBER </div> - {reg}</p>
                        <p> <div className="translate translatealign" data-translate_key="phone_number">PHONE NUMBER </div> - {phone}</p>
                      </div>
                      <div className="col-md-5 text-right printhl">
                      
                        {user.country == "india" ? (
                          <img
                          className="img-fluid chamber-img1"
                            src="assets\img\logo\logo.png"
                          />

                        ) : (
                          <img
                          className="img-fluid chamber-img1"
                            src="assets\img\logo\logo.png"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pdf-body">
                  <div className="print_section">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="top_status">
                          <div className="left_tops">
                            <span className="translate translatealign" data-translate_key="name_of_patent">NAME OF PATIENT</span>-
                            {pname} <br></br>
                            <span><div style={{ paddingLeft: "0px" }} className="translate translatealign" data-translate_key="age">
                              AGE </div> - <div className="translateValue translatealign" > {age}</div>
                            </span>
                            <br></br>
                            <span> <div className="translate translatealign" data-translate_key="sex">
                              Sex </div>-<div className=" translatealign" >{gender_pat == 1 ? "Male" : "Female"}</div>
                            </span>
                          </div>
                          <div className="right_top">
                            <div><div className="translate translatealign" data-translate_key="date_of_consult">DATE OF CONSULT</div> - <div className="translateValue translatealign">{moment(consultdate).format("lll")}</div></div>
                            <br></br>
                            <span><div className="translate translatealign" data-translate_key="patient_id">PATIENT ID</div> -</span>
                            {pat_id}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detail-table">
                      <div className="sub-table">
                        <div className="row m-0 outer-preview-row">
                          <div className="col-md-4">
                            <div className="block1">
                              <h6>
                                <div className="translate translatealign" style={{textDecoration:"underline"}}data-translate_key="physical_examination">PHYSICAL EXAMINATION</div>
                              </h6>
                              <div className="row" style={{ height: "auto" }}>
                                <p>
                                  <b> <div className="translate translatealign" data-translate_key="temperature">Temperature</div> - <div className="translateValue translatealign">{temp}</div>  °F </b>
                                </p>
                                <p>
                                  <b> <div  className="translate translatealign" data-translate_key="pulse">Pulse</div> - <div className="translateValue translatealign">{pulse} /mins</div></b>
                                </p>
                                <p>
                                  <b> <div  className="translate translatealign" data-translate_key="respiratory_rate">Respiratory rate</div> - <div className="translateValue translatealign">{rate} /min</div></b>
                                </p>
                                <p>
                                  <b> <div  className="translate translatealign" data-translate_key="blood_pressure">Blood pressure</div> - <div className="translateValue translatealign">{bp} mmHg</div></b>
                                </p>
                                <p>
                                  <b> <div  className=" translatealign" data-translate_key="height">Height</div> - <div className="translateValue translatealign">{height} cm</div></b>
                                </p>
                                <p>
                                  <b> <div  className=" translatealign" data-translate_key="weight">Weight</div> - <div className="translateValue translatealign">{weight} kg</div></b>
                                </p>
                                <p>
                                  <b> <div  className="translate translatealign" data-translate_key="spo2">SPO2</div> - <div className="translateValue translatealign">{spo2} %</div></b>
                                </p>
                                <p>
                                  <b> <div  className="translate translatealign" data-translate_key="fbs">FBS</div> - <div className="translateValue translatealign">{fbs}</div></b>
                                </p>
                                <p>
                                  <b> <div  className="translate translatealign" data-translate_key="rbs"> RBS</div> - <div className="translateValue translatealign">{rbs}</div></b>
                                </p>
                                <p>
                                  <b> <div  className="translate translatealign" data-translate_key="ppbs"> PPBS</div> - <div className="translateValue translatealign">{ppbs}</div></b>
                                </p>
                                <p>
                                  <b> <div  className="translate translatealign" data-translate_key="blood_group">Blood Group</div> - <div className="translateValue translatealign">{blood_group}</div></b>
                                </p>
                              </div>
                            </div>
                            <div className="block1">
                              <h6>
                                <div style={{textDecoration:"underline"}} className="translate translatealign" data-translate_key="cheif_complaints">CHIEF COMPLAINTS </div>
                              </h6>
                              {/* <div className="row" style={{ height: "auto" }}> */}

                              {/* <u style={{padding:23}}> 
                               <li style={{fontSize:14,fontWeight:400}}> */}
                              {Chiefcomplains &&
                                Chiefcomplains?.map((items) => {
                                  return (
                                    <u style={{ padding: 23 }}>
                                      <li
                                        style={{
                                          fontSize: 14,
                                          fontWeight: 400,
                                        }}
                                      >
                                        <div className="translateValue translatealign">{items}</div>{" "}
                                      </li>
                                    </u>
                                  );
                                })}
                            </div>
                            <div className="block1">
                              <h6>
                                <div style={{textDecoration:"underline"}}  className="translate translatealign" data-translate_key="past_history">PAST HISTORY</div>
                              </h6>
                              <div className="row" style={{ height: "auto" }}>
                                {" "}
                                {past_history != ""
                                  ? past_history &&
                                  past_history.map((items) => {
                                    return (
                                      <u style={{ padding: 23 }}>
                                        <li
                                          style={{
                                            fontSize: 14,
                                            fontWeight: 400,
                                          }}
                                        >
                                          {" "}
                                          <div className="translateValue translatealign">{items}</div>{" "}
                                        </li>
                                      </u>
                                    );
                                  })
                                  : ""}
                              </div>
                            </div>
                            <div className="block1">
                              <h6>
                                <div style={{textDecoration:"underline"}}  className="translate translatealign" data-translate_key="personal_history">PERSONAL HISTORY</div>
                              </h6>
                              <div className="row" style={{ height: "auto" }}>
                                {personal_history &&
                                  personal_history.map((items) => {
                                    return (
                                      <u style={{ padding: 23 }}>
                                        <li
                                          style={{
                                            fontSize: 14,
                                            fontWeight: 400,
                                          }}
                                        >
                                          <div className="translateValue translatealign">{items}</div>
                                        </li>
                                      </u>
                                    );
                                  })}
                              </div>
                            </div>
                            <div className="block1">
                              <h6>
                                <div style={{textDecoration:"underline"}}  className="translate translatealign" data-translate_key="menstrual_history">MENSTRUAL HISTORY</div>
                              </h6>
                              <div className="row" style={{ height: "auto" }}>
                                {" "}
                                {menstrual &&
                                  menstrual.map((items) => {
                                    return (
                                      <u style={{ padding: 23 }}>
                                        <li
                                          style={{
                                            fontSize: 14,
                                            fontWeight: 400,
                                          }}
                                        >
                                          <div className="translateValue translatealign">{items}</div>
                                        </li>
                                      </u>
                                    );
                                  })}
                              </div>
                            </div>
                            <div className="block1">
                              <h6>
                                <div style={{textDecoration:"underline"}}  className="translate translatealign" data-translate_key="known_allergies">KNOWN ALLERGIES</div>
                              </h6>
                              <div className="row" style={{ height: "auto" }}>
                                {" "}
                                {allergies &&
                                  allergies.map((items) => {
                                    return (
                                      <ul style={{ padding: 23 }}>
                                        <li>
                                          <div
                                          style={{
                                            fontSize: 14,
                                            fontWeight: 400,
                                            display:"inline-block"
                                          }}
                                          className="translateValue translatealign"
                                          >
                                            {" "}
                                            {items}
                                          </div>
                                        </li>
                                      </ul>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 left-border right-border">
                            <div className="right_pres_side">
                              <div className="right_prescription">
                                <div className="rx_header">
                                  <h1>
                                    <div className="" data-translate_key="r">
                                      R<sub>x</sub>
                                    </div>
                                  </h1>
                                  <ol>
                                    {pres_item &&
                                      pres_item.map((items, index) => {
                                        return (
                                          <li className="mb-4">
                                            <div style={{fontWeight:"bold"}}> Tab - 
                                            {items.drugs != null
                                              ? items.drugs.name
                                              : ""}
                                            </div>
                                            <br></br>
                                            <div className="translateValue" style={{display:"inline-block"}}>
                                              {getFullForm(items.time_periods,0)}
                                            </div>
                                            <div className="translateValue " style={{display:"inline-block"}}>
                                              {items.time_periods.split(" ")[1] ? getFullForm(items.time_periods,1): ""}
                                            </div>
                                            
                                            <div className="translateValue " style={{display:"inline-block"}}>{items.time_periods.split(" ")[2] ? getFullForm(items.time_periods,2) : ""}</div>
                                            
                                            <div className="translateValue " style={{display:"inline-block"}}>{items.time_periods.split(" ")[3] ? getFullForm(items.time_periods,3) : ""}</div>
                                            <br></br>
                                            <div className="translateValue translatealign"> {items.medicine_time}</div> -
                                            <div className="translateValue translatealign">{items.duration_text}-{items.duration}</div>
                                            <br></br>
                                            <div className="translateValue translatealign">Notes -</div> <div className="translateValue" style={{fontWeight:200,display:"inline-block"}}>{items.note}</div>
                                            
                                          </li>
                                          
                                        );
                                      })}
                                  </ol>
                                </div>
                                <div className="rx_header">
                                  <h3>
                                    <u>
                                      <div  style={{textDecoration:"underline"}}  className="translate translatealign" data-translate_key="advice">ADVICE </div>-
                                      {padvice.map((item, index) => {
                                        return (
                                          <p style={{ fontWeight: 400 }}>
                                            <div className="translateValue translatealign">{index + 1}-{item.name}</div>
                                          </p>
                                        );
                                      })}
                                    </u>
                                  </h3>
                                </div>
                                <div className="rx_header">
                                  <h3>
                                    <u>
                                      <div style={{textDecoration:"underline"}}  className="translate translatealign" data-translate_key="provisional_diagnosis">PROVISIONAL DIAGNOSIS</div>
                                      {prov.map((item, index) => {
                                        return (
                                          <p style={{ fontWeight: 400 }}>
                                            <div className="">{index + 1}-{item.name}</div>
                                          </p>
                                        );
                                      })}
                                    </u>
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="rx_header">
                              <h3>
                                <u>
                                <div style={{textDecoration:"underline"}}  className="translate translatealign" data-translate_key="investigations">INVESTIGATIONS</div> -
                                  {inves.map((item, index) => {
                                    return (
                                      <p style={{ fontWeight: 400 }}>
                                        <div className="translatealign">{index + 1}-{item.name}</div>
                                      </p>
                                    );
                                  })}
                                </u>
                              </h3>
                            </div>
                            <div className="rx_header">
                              <h3>
                                <u>
                                <div style={{textDecoration:"underline"}}  className="translate translatealign" data-translate_key="additional_notes">ADDITIONAL NOTES</div>
                                  {addnote.map((item, index) => {
                                    return (
                                      <p style={{ fontWeight: 400 }}>
                                        <div className="translateValue translatealign">{index + 1}-{item.name}</div>
                                      </p>
                                    );
                                  })}
                                </u>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="header-body">
                        <div className="row">
                          <div className="disclaimer">
                            <div className="translate" data-translate_key="disclaimer"></div>
                            <div className="translateValue translatealign">Disclaimer: This prescription is issued on the
                            information provided by you through
                            teleconsultation. Visit doctor or hospital in case
                            of emergency. This prescription is valid only in
                            India.</div>
                            <br></br><div className="translateValue translatealign">We do not promote any particular brand of
                            medicine, patient can go for generic composition or
                            opt for any other brand available.</div>
                          </div>
                          <div className="disclaimer-right">
                            <div className="sign-box text-center">
                              <div className="translateValue translatealign" >{drname}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 text-center m-10 remove_under">
                           
                        <div className="translateValue translatealign">Vedant Medyseva</div><br></br>
                        <div className="translateValue translatealign">Digdoh, Hingna Rd, Digdoh Hills,</div>
                        <div className="translateValue translatealign">Police Nagar, Nagpur, Digdoh, Maharashtra 440019</div>                      
                          <br></br>
                          <div className="translateValue translatealign">PHONE</div>- +91 93415 49721, +91 75669 75666 , 
                          <div className="translateValue translatealign">EMAIL</div>-{" "}
                          <span style={{ color: "#1088de" }}>
                            {" "}
                            medyseva@vedantpay.com
                          </span>
                          <br></br>
                          <div className="translateValue translatealign">WEBSITE</div>: https://medyseva.vedantpay.com/
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div printableId="printme"></div>
          </div>
          <footer className="main-footer hidden-print">
            <div className="pull-right d-none d-sm-inline-block">
              <div className="version">Version: 1.3</div>
            </div>
            <a href="">Medyseva © 2023 All rights reserved.</a>
          </footer>
          {/* </div> */}
          <Modal show={showModal} onHide={handleModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Select Language</Modal.Title>
              </Modal.Header>
              <Modal.Body>          
                <div className="container h-100">
                  <div className="row h-100 justify-content-center align-items-center"> 
                    <Button className="m-2" variant="primary" onClick={()=>triggerLanguageChangeOnSelection('en')}>
                      {chosenLanguage === "en" ? <CheckCircle/> : <CheckCircleOutline/>}                
                        English
                    </Button>  
                    <Button className="m-2" variant="primary" onClick={()=>triggerLanguageChangeOnSelection('hi')}>
                      {chosenLanguage === "hi" ? <CheckCircle/> : <CheckCircleOutline/>}
                        Hindi
                    </Button>  
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                  Close
                </Button>                
              </Modal.Footer>
            </Modal>
          <Modal
            className="appointment-modal"
            show={showed}
            onHide={handleClose}
            animation={false}
            centered
          >
            <Modal.Header className="head-modal1" closeButton>
              <Modal.Title>File Upload <span style={{ color: 'red' }}> <p>(Maximum Upload size- 2MB)</p></span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <div className="">
                  {inves.map((item, index) => {
                    console.log(item);
                    return (
                      <Row key={item.id}>
                        {/* <p style={{ color: 'red' }}></p> */}
                        <div className=" mb-4">
                          <Form.Label className="bold-head">
                            {item.name}
                          </Form.Label>
                          <div className="box-multi">
                            <label
                              className="multippic"
                              for={index + "inputTag"}
                            >
                              <i class="fa fa-2x fa-camera"></i>
                              <br />
                              <b>Select Image-</b>
                              <br />
                              <b>Upload only- PDF , PNG , JPEG , JPG</b>

                              <input
                                className="inputTag"
                                id={index + "inputTag"}
                                type="file"
                                onChange={(e) =>
                                  photoUploadHandler(e, setPhoto, item)
                                }
                                // onfocus={clearF(this)}
                                multiple="multiple"
                              />
                              <br />
                              <span className="imageName" id="imageName"></span>
                            </label>
                          </div>
                        </div>
                      </Row>
                    );
                  })}

                  <Container>
                    <div
                      className="justify-content-center"
                      style={{ display: "flex" }}
                    >
                      <Button
                        className="btn btn-primary margin-btn"
                        onClick={() => setShow(false)}
                      >
                        Close
                      </Button>
                      <Button
                        disabled={fileprocess ? true : false}
                        className="btn btn-primary margin-btn"
                        type="button"
                        onClick={() => HandleSubmit()}
                      >
                        {fileprocess ? 'Processing' : 'submit'}
                      </Button>
                    </div>
                  </Container>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
          <Modal
            className="appointment-modal"
            show={reportshow}
            onHide={reporthandleClose}
            animation={false}
            centered
          >
            <Modal.Header className="head-modal1" closeButton>
              <Modal.Title>File Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <Row>
                  <div className="pb-3" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                    <Row>


                      {diagnos != '' ? diagnos.map((items, index) => {
                        return (

                          <Col md="6" sm="6">
                            <div className="modal-box pb-3">
                              <h6>{items.name}</h6>
                              <a
                                href={items.file}
                                target="_blank"
                              >
                                {" "}
                                <img className="image-background" src={items.file} height="65px" width="65px" />
                              </a>


                            </div>
                          </Col>


                        );
                      }) : <h5>No Report Uploaded</h5>}
                    </Row>
                  </div>

                </Row>

                <Container>
                  <div
                    className="justify-content-center"
                    style={{ display: "flex" }}
                  >
                    <Button
                      className="btn btn-primary margin-btn"
                      onClick={() => setreportShow(false)}
                    >
                      Close
                    </Button>
                    {/* <Button
                      className="btn btn-primary margin-btn"
                      type="button"
                      onClick={() => HandleSubmit()}
                    >
                      Submit
                    </Button> */}
                  </div>
                </Container>
              </div>
            </Modal.Body>
          </Modal>
        </Dashboard_layout>
      </>
    </div>
  );
};
export default Prescription;
