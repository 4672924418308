import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import useAuth from "../../../hooks/useAuth";

import {
  IoIosApps,
  IoIosBrowsers,
  IoIosClock,
  IoMdAlbums,
  IoIosWallet,
  IoIosAlbums,
  IoIosUnlock,
  IoIosCube,
  IoIosToday,
  IoIosHome,
  IoMdLogOut,
  IoMdCash,
} from "react-icons/io";
import { Button } from "react-bootstrap";
import { logout } from "../../../reducer/authReducers";
import { useDispatch } from "react-redux";
import axiosMain from "../../../http/axios/axios_main";

const Sidebar = () => {

  const { user, last_login } = useAuth();
  const dispatch = useDispatch();

  const [isCheck, setIsCheck] = useState("");
  useEffect(() => {
    const value1 = localStorage.getItem("minimum");
    setIsCheck(value1);
  });

  const handleLogout = async () => {
    dispatch(logout());
  };

  return (
  
    <aside
      className="sidenav navbar navbar-vertical navbar-expand-xs border-0  fixed-start "
      id="sidenav-main"
    >
      <div className="sidenav-header hidden-print">
        <i
          className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
          aria-hidden="true"
          id="iconSidenav"
        />
        {/* <Link className="navbar-brand m-0" to="/">
          <img
            className="image-logo-header-sidebar"
            src="user_assets/images_new/3dblack-coin_2 1.png"
            alt="img"
          />
        </Link> */}
      </div>
      {/*     <hr className="horizontal dark mt-0"> */}
      <div
        className="collapse navbar-collapse  w-auto  max-height-vh-100 h-100 py-4 aside-overflow hidden-print"
        id="sidenav-collapse-main"
      >
        <ul className="navbar-nav">
          <li className="nav-item nav-bar-new">
            <NavLink className="nav-link mx-auto" to="/dashboard">
              <div className="border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                {<IoIosApps />}
              </div>
              <span className="nav-link-text ms-1">Dashboard</span>
            </NavLink>
          </li>
          <li className="nav-item nav-bar-new">
            <NavLink className="nav-link mx-auto" to="/patient">
              <div className="border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                {<IoIosBrowsers />}
              </div>
              <span className="nav-link-text ms-1">Patients List</span>
            </NavLink>
          </li>

          {/* <li className="nav-item nav-bar-new pb-3">
            <NavLink className="nav-link mx-auto" to="/appointment_create">
              <div className="border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                {<IoIosClock />}
              </div>
              <span className="nav-link-text ms-1">Appointment Create</span>
            </NavLink>
          </li>*/}
          <li className="nav-item nav-bar-new">
            <NavLink className="nav-link mx-auto" to="/appoint_list">
              <div className="border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                {<IoIosClock />}
              </div>
              <span className="nav-link-text ms-1">Appointment List</span>
            </NavLink>
          </li>
          <li className="nav-item nav-bar-new">
            <NavLink className="nav-link mx-auto" to="/transaction">
              <div className="border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                {<IoIosAlbums />}
              </div>
              <span className="nav-link-text ms-1">Transaction List</span>
            </NavLink>
          </li>
          {/* <li className="nav-item nav-bar-new">
            <NavLink className="nav-link mx-auto" to="/wallet">
              <div className="border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                {<IoIosWallet />}
              </div>
              <span className="nav-link-text ms-1">Wallet Details</span>
            </NavLink>
          </li> */}
          <li className="nav-item nav-bar-new ">
            <NavLink className="nav-link mx-auto" to="/invoice">
              <div className="border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                {<IoIosCube />}
              </div>
              <span className="nav-link-text ms-1">Invoices</span>
            </NavLink>
          </li>
          {/* <li className="nav-item nav-bar-new pb-3">
            <NavLink className="nav-link mx-auto" to="">
              <div className="border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                {<IoIosToday />}
              </div>
              <span className="nav-link-text ms-1">Withdraw request</span>
            </NavLink>
          </li> */}

          {/* <li className="nav-item nav-bar-new ">
            <NavLink className="nav-link mx-auto" to="/topup_list">
              <div className="border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                {<IoMdCash />}
              </div>
              <span className="nav-link-text ms-1" href="" variant="primary">
                Top Up
              </span>
            </NavLink>
          </li> */}


          <li className="nav-item nav-bar-new ">
            <NavLink className="nav-link mx-auto" to="/patientreport">
              <div className="border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                {<IoMdCash />}
              </div>
              <span className="nav-link-text ms-1" href="" variant="primary">
                Patient Report
              </span>
            </NavLink>
          </li>

          {user.country=="india" ? (
                    <li className="nav-item nav-bar-new ">
                    <NavLink className="nav-link mx-auto" to="/abha-list">
                      <div className="border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                        {<IoMdCash />}
                      </div>
                      <span className="nav-link-text ms-1" href="" variant="primary">
                        Create ABHA Card
                      </span>
                    </NavLink>
                  </li>

                    ):<></>}
         
          {/* <li className="nav-item nav-bar-new ">
            <NavLink className="nav-link mx-auto" to="/abha-login">
              <div className="border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                {<IoMdCash />}
              </div>
              <span className="nav-link-text ms-1" href="" variant="primary">
                ABH Login
              </span>
            </NavLink>
          </li> */}
          <li className="nav-item nav-bar-new ">
            <NavLink className="nav-link mx-auto" to="">
              <div className="border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                {<IoMdLogOut />}
              </div>
              <span
                className="nav-link-text ms-1"
                onClick={async () => {
                  handleLogout();
                }}
                href=""
                variant="primary"
              >
                Logout
              </span>
            </NavLink>
          </li>

          {/* <li className="nav-item nav-bar-new pb-3">
            <NavLink className="nav-link mx-auto" to="/">
              <span className="nav-link-text ms-1">
                <Button
                 
                >
                  Logout
                </Button>
              </span>
            </NavLink>
          </li> */}
        </ul>
      </div>
    </aside>

  );
};
export default Sidebar;
