import React from "react";
import Dashboard_layout from "../Layout/Dashboard_layout";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { useState } from "react";
import axiosMain from "../../http/axios/axios_main";
import { useLocation } from 'react-router-dom';
import { generatePath, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import Prescription from './Prescription';
import { Button, Col, Container, Form, FormControl, FormGroup, FormLabel, InputGroup, Row } from "react-bootstrap";
import axiosNew from './../../http/axios/axios_new';
import { useDispatch } from "react-redux";
import { agoraids } from "../../reducer/authReducers";
import helpers from "../../Helper/CryptoHelper";
const Appointment_list = () => {
  const { state } = useLocation("/");
 const dispatch=useDispatch();

  // const navigate =useNavigate();
  const navigate = useNavigate();

  const { user, isAuthenticated } = useAuth();
  //const user1 = user._id
  //console.log(user1,"user_id");

  const [apiData, setApiData] = useState({
    isloading: false,
    data: [],
    error: "",
  });

  function renderAppointmetnType(type){
    var typeNomenclature = '';
    if(type === 1){
      typeNomenclature = 'General';
    }else if(type===2){
      typeNomenclature = 'Special'
    }else if(type===3){
      typeNomenclature = 'Super Special';
    }
    return typeNomenclature;
  }
  
  const [patient, setPatient] = useState(false);

  const [precriptiontype, setprecriptiontype] = useState('');

  const [appointtype, setappointtype] = useState('');
  const [followup, setfollowup] = useState('');

  const [appointment, setAppointment] = useState(false);
  const [searchTerm, setsearchTerm] = useState("");

  const [doctor, setDoctor] = useState(false);

  const [show, setShow] = useState(false);
  const [patient_modal, setpatient_modal] = useState(false);
  const [pmobile_modal, setpmobile_modal] = useState(false);
  const [paddress_modal, setpaddress_modal] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 20;
  const [inputdatacount, setinputdatacount] = useState({
    start: '',
    end: ''

  });
  const pageVisited = pageNumber * usersPerPage;
  // const [precription_id, setprecription_id] = useState('');
  const [inputdata, setInputdata] = useState({
    pagecount: 30,
  });
  const formdata = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputdata({ ...inputdata, [name]: value });
  };
  const formdatacount = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setinputdatacount({ ...inputdatacount, [name]: value });
  };
  const downloadexcel = () => {

    if (inputdatacount.start && inputdatacount.end != '') {

      // axios.post("https://api.medyseva.com/api/vle/wallet/export", {

      axiosNew.post("/api/vle/appointment/export", {

        vle_id: user.id,
        start_date: inputdatacount.start,
        end_date: inputdatacount.end

      },
        {
          responseType: "blob"
        })
        .then(response => {
          let blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }),
            url = window.URL.createObjectURL(blob);
          window.open(url);
        });

    } else {

      toast.warn('Please enter Valid date');

    }


  }

  const getData = async () => {

    if (state != null) {
      toast.success('Feedback Submitted !Thanks');

    }
    try {
      setApiData((prev) => {
        return {
          ...prev,
          isloading: true,
        };
      });

      // const api = await axios.post("https://api.medyseva.com/api/vle/appointment", {
      const api = await axiosNew.post("/api/vle/appointment", helpers.encrypt(JSON.stringify({

        vle_id: user.id,
        limit: 2000,
        offset: 0,
        prescription_type: precriptiontype,
        appointment_type: appointtype,
        follow_up: followup,
      })));
      api.data = helpers.decrypt(JSON.stringify(api.data));
      console.log(api.data.original, "preshelp");

      setApiData({
        isloading: false,
        data: api.data.original.data.slice(0, 500),
        error: "",
      });
    } catch (error) {
      setApiData({
        isloading: false,
        data: [],
        error: error.data,
      });

      console.log(error, 'liveerror');
    }


  };
  useEffect(() => {
    getData();
  }, [precriptiontype, appointtype, followup]);

  const handleAppointment = async (appointData) => {
    console.log("data", appointData);
    // let res = await axios.post("https://api.medyseva.com/api/vle-appointment-details", {

    let res = await axiosNew.post("/api/vle-appointment-details", {
      id: appointData.id,
    });


    setpatient_modal(appointData.patient.name);
    setpmobile_modal(appointData.patient.mobile);
    setpaddress_modal(appointData.patient.present_address);
    console.log("data", res.data);
    if (res.data.status == 1) {
      setPatient(res.data.patient);
      setAppointment(res.data.appointment);
      setDoctor(res.data.doctor);

      setShow(true);
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleprescription = (data) => {
    // console.log(data.prescription_id, "pid");

    navigate("/prescription", { state: { presid: data.prescriptions[0].id } });
  };

  const handlegeninvoice = (data) => {
    // console.log(data,'pid');

    navigate("/invoice-generate", {
      state: {
        presid: data.prescriptions[0] == null || data.prescriptions[0] == "" ? 0 : data.prescriptions[0].id,
        vleid: data.added_by,
        apointid: data.id,

        // invoiceid: data.invoice.id,
      },
    });
  };

  const handlecreateinvoice = async (data) => {
    // console.log(data,'pid');
    const apis = await axiosNew.post("/api/vle/invoice/create", {
      // const apis = await axios.post("https://api.medyseva.com/api/vle/invoice/create", {

      vle_id: data.added_by,
      appoinment_id: data.id,
      prescription_id: data.prescriptions[0].id,
    });
    console.log(apis.data.msg, "data aa gaya");
    // setinvoice(apis.data.msg);
    if (apis.data.status == 1) {
      toast.success("Invoice Created Successfully");

      setTimeout(getData(), 3000);
    }
  };


  const pageCount = Math.ceil(apiData.data.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    window.scrollTo(0, 0);

  }


  const createinvoice = async (data) => {

    // var prescripid_create = '';
    // data.prescription.map((items) => prescripid_create = items.id)

    navigate("/createinvoice", { state: { prescriptionid: data.prescriptions[0].id } });
  };
const agora_screen=(data)=>{
  localStorage.setItem("agoraaddress", JSON.stringify(data));

  // dispatch(
  //   agoraids({
  //     user: {
  //       appid:data
  //     },
  //   })
  // );
window.location.href='/video'
  // navigate('/video');
}

  console.log(precriptiontype, 'precriptiontypeprecriptiontypeprecriptiontype');
  return (
    <Dashboard_layout>
      <ToastContainer />



      <div className="content-top-padding">
        <section className="card-outer-box">
          <div className="inner-content">
            <Form>
              <Row>
                <Col sm="4">
                  <FormGroup className="mb-3">
                    <FormLabel>Prescription Type </FormLabel>
                    <InputGroup>
                      <select
                        onChange={(e) => setprecriptiontype(e.target.value)}
                        defaultValue={precriptiontype}
                        // name="pagecount"
                        // value={inputdata.pagecount}
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option value="">Select</option>

                        <option value="yes">Yes</option>
                        <option value="no">No</option>

                      </select>
                    </InputGroup>
                  </FormGroup>
                </Col>


                {/*<Col sm="4">
                  <FormGroup className="mb-3">
                    <FormLabel>Appointment Type </FormLabel>
                    <select
                      onChange={(e) => setappointtype(e.target.value)}
                      defaultValue={appointtype}
                      // name="pagecount"
                      // value={inputdata.pagecount}
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option value="">Select</option>

                      <option value="1">General</option>
                      <option value="2">Special</option>
                      <option value="3">Super Special</option>


                    </select>
                  </FormGroup>
                </Col>*\}

                <Col sm="4">
                  <FormGroup className="mb-3">
                    <FormLabel>Follow up Type </FormLabel>
                    <select
                      onChange={(e) => setfollowup(e.target.value)}
                      defaultValue={followup}
                      // name="pagecount"
                      // value={inputdata.pagecount}
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option value="">Select</option>

                      <option value="Yes">Yes</option>
                      <option value="No">No</option>

                    </select>
                  </FormGroup>
                </Col>

                {/* <div className="col-sm-6">
      <div className="drop-flex">
        <label>Search:</label>
        <input
          onChange={(event) => { setsearchTerm(event.target.value) }}
          type="search"
          className="form-control input-sm"
          placeholder="Search by name..."
          aria-controls="appoinment_table"
        ></input>
      </div>
    </div> */}
              </Row>
            </Form>
          </div>
        </section>
      </div>
       {/* <section className="card-outer-box">
              <div className="inner-content">
                <form>
                  <div className="row">
                    <div className="col-md-4 mb-1">
                      <div className="input-group date" id="datepicker">
                        <input type="date" className="form-control" name="start" value={inputdatacount.start} onChange={formdatacount} id="date" />
                      </div>
                    </div>
                    <div className="col-md-4 mb-1">
                      <div className="input-group date" id="datepicker">
                        <input type="date" onChange={formdatacount} name="end" value={inputdatacount.end} className="form-control" id="date" />
                      </div>
                    </div>
                    <div className="col-md-4 mb-1">
                      <button type="button" className="btn btn-primary" onClick={downloadexcel}>
                        Export To Excel
                      </button>
                    </div> 
                  </div>
                </form>
              </div>
            </section>  */}
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary mt-2 pull-left">
            Appointments
          </h6>
          <a

            href="/appointment_create"
            className="btn btn-primary pull-right"
          >
            Create Appointment
          </a>
          {/* <a
              onClick={(e) => handleTopup()}
              className="btn btn-primary pull-right"
            >
              Topup Requests
            </a> */}
        </div>


        <div className="card-body">
          <div className="row">
            <div className="col-sm-6">
              {/* <div className="drop-flex">
                  <p>Show </p>
                  
                  <select onChange={formdata}
                    name="pagecount"
                    value={inputdata.pagecount}
                    className="form-select"
                    aria-label="Default select example"
                  >
              
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="300">300</option>
                  </select>

                  <p>entries</p>
                </div> */}
            </div>
            <div className="col-sm-6">
              <div className="drop-flex">
                <label>Search:</label>
                <input
                  style={{ marginBottom: 10 }}
                  onChange={(event) => { setsearchTerm(event.target.value) }}
                  type="search"
                  className="form-control input-sm"
                  placeholder="Search by name..."
                  aria-controls="appoinment_table"
                ></input>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table
              className="table table-bordered vle-table"
              id="dataTable"
              width="100%"
              cellSpacing={0}
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Patient Info</th>

                  <th>Schedule Info</th>
                  <th>Appointment type</th>
                  <th>Follow up </th>
                  <th>Doctor</th>
                  <th>Payment Status</th>

                  <th> Status</th>
                  <th width="20%">Action</th>
                </tr>
              </thead>
              <tbody>
                {apiData.isloading && (
                  <tr>
                    <td
                      style={{ textAlign: "center", color: "red" }}
                      colSpan="9"
                    >
                      Data loading
                    </td>
                  </tr>
                )}

                {!apiData.isloading && apiData.data.length > 0
                  ? apiData.data && apiData?.data?.filter((items) => {
                    if (searchTerm == "") {
                      return items;
                    } else if (items.patient.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                      return items;
                    }
                  })?.slice(pageVisited, pageVisited + usersPerPage).map((items, key) => {
                    return (
                      <tr role="row" className="odd">
                        <td>{pageVisited + (key + 1)}</td>
                        <td>
                          <p>
                            <strong>Name : </strong>
                            {items.patient != null ? items.patient.name : ''}
                            <span>({items.patient != null ? items.patient.mr_number : ''})</span>
                          </p>
                          <p>
                            <strong>Phone : </strong>
                            {items.patient != null ? items.patient.mobile : ''}
                          </p>
                          <p>
                            <strong>Email : </strong>
                            {items.patient != null ? items.patient.email : ''}
                          </p>
                        </td>



                        <td>
                          <label className="badge badge-primary-soft brd-20">
                            <i className="fa fa-calendar"></i>&nbsp;{" "}
                            {items.date}{" "}
                          </label>
                        </td>
                        <td>{ renderAppointmetnType(items.appointment_type) }</td>

                        <td> {items.follow_up ? items.follow_up.toUpperCase() : ''}</td>
                        <td> {items.doctor ? items.doctor.name : ''}</td>
                        <td>
                          <label className="badge badge-danger-soft brd-20" style={{ background: '#c8efd1c9', color: '#28a745' }}>
                            {/* {items.payment_user == null ? 'Pending' : items.payment_user} */}
                            Paid
                          </label>
                        </td>
                        <td>Success</td>
                        <td>
                          <div style={{ display: "flex" }}>
                            {items.payment_user == null && items.follow_up != 'Yes' ? (
                              <a
                                onClick={(e) => handlegeninvoice(items)}
                                className="btn btn-light-secondary btn-sm"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="View Invoice"
                                id="in_voice"
                              >
                                <i className="fas fa-paper-plane"></i>
                              </a>
                            ) : (
                              <div></div>
                            )}

                            {/* <a
                              onClick={(e) => handlegeninvoice(items)}
                                className="btn btn-light-secondary btn-sm"
                                href=""
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Create Invoice"
                              >
                                <i className="fas fa-paper-plane"></i>
                              </a> */}
                            <a
                              className="btn btn-primary btn-sm start_meeting"
                              href={items.video_link}
                              data-id="7039"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Video Link"
                              target="_blank"
                              // onClick={()=>agora_screen(items)}
                            >
                              <i className="fas fa-video"></i>
                            </a>

                            <a
                              href="#"
                              onClick={(e) => handleAppointment(items)}
                              className="btn btn-light-secondary btn-sm"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="View"
                            >
                              <i className="far fa-eye"></i>
                            </a>

                            {items.prescriptions != '' ? (
                              <a
                                href=""
                                onClick={(e) => handleprescription(items)}
                                className="btn btn-light-secondary btn-sm"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Prescription View"
                              >
                                <i className="far fa-address-card">
                                  {/* {items.payment_user != null
                                    ? items.payment_user.invoice_id
                                    : ""} */}
                                </i>
                              </a>
                            ) : (
                              <div></div>
                            )}


                            {items.invoice_lab == null && items.prescriptions != '' ?

                              (
                                <a
                                  onClick={(e) => createinvoice(items)}
                                  className="btn btn-light-secondary btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Create Investigation Invoice"
                                  placeholder="Create Invoice"
                                >
                                  <i className="fa fa-address-book"></i>
                                </a>
                              ) : (
                                <div></div>
                              )}
                            

                            {items.feedback_status == 'no'  ?

                              (
                                <a
                                  onClick={(e) => navigate('/feedback', { state: { appointid: items.id, pid: items.patient.id } })}
                                  className="btn btn-light-secondary btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Feedback"
                                >
                                  <i className="fas fa-comment-dots"></i>
                                </a>
                                 ) : (
                                  <div></div>
                                )}
  

                          </div>

                        </td>
                      </tr>
                    );
                  })
                  : ""}
              </tbody>
            </table>

            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
      <Modal
        className="appointment-modal"
        show={show}
        onHide={handleClose}
        animation={false}
        centered
      >
        <Modal.Header className="head-modal1" closeButton>
          <Modal.Title>Patient Appointment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <h4 className="bold-head">Patient Information</h4>
            <Row className="justify-content-center">
              <Col sm={4}>
                <p>
                  <b>Name: </b> {patient_modal}
                </p>
              </Col>
              <Col sm={4}>
                <p>
                  <b>Mobile: </b> {pmobile_modal}
                </p>
              </Col>
              <Col sm={4}>
                <p>
                  <b>Address: </b> {paddress_modal}
                </p>
              </Col>
            </Row>
          </Container>

          {/* <Container>
            <h4 className="bold-head mt-3">Doctor Information</h4>
            <Row className="justify-content-center">
              <Col sm={4}>
                <p>
                  <b>Name: </b> {doctor && doctor.name}
                </p>
              </Col>
              <Col sm={4}>
                <p>
                  <b>Mobile: </b> {doctor && doctor.phone}
                </p>
              </Col>
              <Col sm={4}>
                <p>
                  <b>Address: </b> {doctor && doctor.address}
                </p>
              </Col>
            </Row>
          </Container> */}

          <Container>

          </Container>
        </Modal.Body>
      </Modal>
    </Dashboard_layout>
  );
};

export default Appointment_list;
