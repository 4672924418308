import useAuth from "../hooks/useAuth";
import axiosMain from "../http/axios/axios_main";
import Swal from 'sweetalert2'
const CommonHelper = {
    
    authUserRole:function(){
        return localStorage.getItem('role')!=='' && localStorage.getItem('role')!==null && localStorage.getItem('role')!== undefined ? localStorage.getItem('role') : "vle";
    },
    getBalance:async function(user){
        if(user.bcid!="" || user.bcid!=null ){
            let res = await axiosMain.post("/api/vedant/partner/checkbalance/"+user.bcid)  
            if(!res.data.balanceValidation){
                Swal.fire({
                    title: 'Low balance, cannot proceed!',
                    text: res.data.message,
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                  })
            }
            
        }
    }
}
export default CommonHelper;