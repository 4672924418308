import React, { useState, useEffect,useRef,useCallback} from "react"; 
import { toast, ToastContainer } from 'react-toastify';
import axiosNew from "../../http/axios/axios_new";
import { useDispatch } from "react-redux";
import { login } from "../../reducer/authReducers";
import { useNavigate } from "react-router";
import { setAuthorizationToken } from "../../reducer/authReducers";
import { setLoginAddress } from "../../reducer/authReducers";
function Authenticate(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        authenticateUser();       
        console.log(1234)
    },[]);
    const authenticateUser = async () =>{
        console.log('caling everything')
            console.log("working")
            const url = window.location.search;
            const getParam = new URLSearchParams(url);
            const getToken = getParam.get('login_token')
            let config = {
                "headers":{
                    'X-Client-Secret':'76eaf7f6fee2f5b530955b33dc40fdaa616a608f8221aa6ebabc1121940f5f7d',
                    'X-Client-Id':'76d27c92-86ee-44d6-a627-682837af7425'
                }
            }
            
            const response = await axiosNew.post("api/vedant/partner/authenticate?login_token="+getToken,getToken,config);
            console.log(response)
            console.log(response, "finelogin");
            if (response?.data.status) {
                dispatch(
                    login({
                        user: response.data.user,
                        isAuthenticated: true,                
                    })         
                );
                localStorage.setItem('token',response.data.token)
                dispatch(
                    setAuthorizationToken(response.data.token)
                );
                dispatch(
                    setLoginAddress(JSON.stringify(response.data.last_login))
                );            
                navigate("/dashboard",{ state: { logalert: "loginalert" } });
            }          
        }   
    return "Do not close or refresh this page";
}
export default Authenticate;